.DadhboardHeader{
    box-shadow: 0px 0px 10px #00000054;
}
.DadhboardHeader .login-btn{
    background: var(--theme);
}

.DadhboardHeader .nav-body .nav-link-active{
    color: var(--theme ) !important;
}

@media all and (min-width: 0px) and (max-width: 576px) {
    .DashboardItems{
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0%;
        top: 0%;
        background-color: #fff;
        height: 100vh;
        justify-content: flex-start !important;
        overflow: hidden;
        opacity: 1;
        z-index: 1000;
        /* padding-top: 10% !important;  */
        transform: translateX(100%);
        transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);


    }
    .DashboardItemsOpen{
        box-shadow: 0 -10px 30px #0000005b;
        transform: translateX(0%) !important;
    }

    .DashboardItems .nav-link{
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 3rem;
        margin: 5% 0!important;
        font-size: larger;
    }
    .DashboardItems .nav-link .text{
        order: 1;
        padding: 0.5rem 1rem;
    }

    .DashboardItems .login-btn{
        width: fit-content !important;
    }

    .DashboardItems .closeBtn{
        background-color: #fff;
        border: none;
        padding: 0 1rem;
        margin: 0.5rem;
        font-size: x-large;
        align-self: flex-start;
    }

    .DashboardItems .openBtn{
        background-color: #fff;
        border: none !important;
        padding: 0 1rem;
        margin: 0.5rem;
    }
}