code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  padding: 0%;
  margin: 0%;
  /* box-sizing: border-box; */
}
body {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  font-family: "Poppins" -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh !important;
}

.brStandard {
  border-radius: var(--borderRadius) !important;
}

.mainContainer {
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100% !important;
}
.ultynone {
  list-style-type: none;
}
.my-green {
  background: transparent;
  color: #8f9a6b;
}

.submit-btn {
  background: #8f9a6b;
  color: #fff;
  font-weight: 500 !important;
  cursor: pointer;
}

/* ======================================== Header ============================================= */
header {
  position: sticky !important;
  top: 0%;
  left: 0%;
  z-index: 100;
  background: rgb(249, 249, 249);
  box-shadow: 0px 0px 10px #00000933;
  overflow: visible !important;
  transition: 0.5s;
}
.navdown .nav-item {
  display: flex;
}
.logoonHead {
  height: 100%;
  max-height: 50px;
}
.nav-link {
  transition: 0.5s;
}
.nav-body .nav-link:hover {
  color: #8f9a6b !important;
}
.nav-body .nav-link-active {
  color: #8f9a6b !important;
  font-weight: 700;
}

.login-btn {
  background-color: #8f9a6b;
  color: #fff;
  text-align: center;
  transition: 0.5s;
  padding: 2px 15px;
}

.login-btn:hover {
  border-radius: var(--borderRadius) !important;
  color: #fff;
  background: #fff;
}

/* slider */
.sliderSection {
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  /* display: flex; */
  background-color: #000;
  /* object-fit: cover; */
  object-position: center;
}
.slide-img {
  height: 100% !important;
  width: 100% !important;
  max-height: 500px;
}

.carouselExample {
  position: relative;
  height: 100%;
}

.carousel-btns {
  position: absolute;
  padding: 15% 10%;
  z-index: 10;
  background-color: #8f9a6b;
}

/* ================ Header On Dashboard ============== */
.menus-container {
  border-radius: var(--borderRadius);
  border: 2px solid red;
}

/* footer */
footer {
  box-shadow: 0px 0px 10px #0000002e;
  z-index: 12;
}

form {
  box-shadow: 5px 5px 10px rgba(128, 128, 128, 0.231);
  width: 70%;
}

.ForgottenSection form {
  width: 50%;
}

.logo-conatiner,
#toggleBtn {
  z-index: 11;
}

/* .navdown{
  position: absolute;
  top: 0%;
  padding: 3%;
  left: 0%;
  width: 100vw !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end !important;
  align-items: center !important;
  height: 65vh !important;
  min-height: 400px;
  background-color: #fff;
  z-index: 10;
  transition: 1s;
  animation: slideDown 0.3s linear both;
  box-shadow: 0px 10px 20px #8f9a6b53 ;
  border-bottom-left-radius:10% ;
  border-bottom-right-radius:10% ;
}
.navdown .nav-link{
  align-self: center;
  margin: 3%;
  left: -3%;
  text-align: center;
}
.navdown .login-btn{
  width: fit-content;
  padding: 2% 10%;
  left: -3%;

}
.navdown .login-btn .nav-link{
  width: 100%;
} */

.fill-green {
  fill: lightgreen !important;
}

/* ===================================== Admin Page ==================================== */

.table-row {
  overflow-x: auto;
}
.table-row::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}
/* Track */
.table-row::-webkit-scrollbar-track {
  background: linear-gradient(to right, #8f9a6b, #5b6441);
}
/* Handle */
.table-row::-webkit-scrollbar-thumb {
  background: linear-gradient(#fffafa, #8f9a6b);
  border-radius: var(--borderRadius);
}
/* Handle on hover */
.table-row::-webkit-scrollbar-thumb:hover {
  background: #232323;
}

/* ===================================== Admin Page ==================================== */

/* ===================================== profile Page ==================================== */
.bshd {
  box-shadow: 5px 5px 10px rgba(128, 128, 128, 0.231);
}
.profilepagebox {
  height: 100%;
  align-self: center;
  max-height: 450px;
}
.profilepagebox .profile-edit-btn {
  background: #bcbbbb21;
  width: 40px;
  height: 40px;
  border: 1px solid #74747421;
  position: absolute;
  top: 0%;
  right: 0%;
  display: grid;
  place-items: center;
}
.profilepagebox .profile-edit-btn i {
  color: #4342427f;
}
.pdfsec {
  overflow-y: auto;
}
.pdfsec a {
  color: #8f9a6b;
}
.pdfsec::-webkit-scrollbar {
  width: 10px;
}
/* Track */
.pdfsec::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
.pdfsec::-webkit-scrollbar-thumb {
  background: linear-gradient(#8f9a6b, rgb(0, 0, 0));
  border-radius: 5px;
}
/* Handle on hover */
.pdfsec::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Profile */
.profile-container {
  width: 60%;
  border: 1px solid #15151525;
  box-shadow: 5px 5px 10px rgba(128, 128, 128, 0.231);
}
.p-data b {
  font-size: x-large;
}
.p-data label {
  font-size: larger;
  text-align: center;
  margin: 1% 3%;
}

/* ===================================== profile Page ==================================== */

/* ===================================== Contact Page ==================================== */

textarea {
  min-height: 150px !important;
  max-height: 500px !important;
  overflow-y: scroll;
}
.ContactDetail,
.ContactEmailsContainer {
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  width: 60%;
  border: 1px solid #15151525;
  box-shadow: 5px 5px 10px rgba(128, 128, 128, 0.231);
  border-radius: var(--borderRadius);
  min-height: 500px;
}

.ContactEmailsContainer .Cemail {
  width: 100%;
  border-radius: var(--borderRadius);
  border: 1px solid #8f9a6b53;
  font-size: x-large;
  background: linear-gradient(to right, #eef7d163, #f6ffd9bb);
  cursor: pointer;
}

.ContactItems {
  border: 1px solid #8f9a6b !important;
}

/* ===================================== Contact Page ==================================== */

/* ===================================== Machines Dashboard ==================================== */
/* .DashboardNav{
  position: absolute;
  z-index: 50;
} */
/* ------------- Layout ---------------- */
.main-panel {
  height: 100vh !important  ;
  overflow-y: auto !important;
  z-index: 6;
}

.main-panel::-webkit-scrollbar {
  width: 0px;
}
/* Track */
/* .main-panel::-webkit-scrollbar-track {
  background: transparent;
  background: transparent;
  /* box-shadow: -5px 5px 10px #555 inset; 
}
/* Handle 
.main-panel::-webkit-scrollbar-thumb {
  border-radius: 5px;
} */
/* Handle on hover */
/* .main-panel::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

/* ------------- Layout ---------------- */

.Dashboard {
  height: 100% !important;
  min-height: 90vh;
}
/* width */
.Dashboard::-webkit-scrollbar {
  width: 10px;
}
/* Track */
.Dashboard::-webkit-scrollbar-track {
  background: transparent;
  background: transparent;
  box-shadow: 0px 0px 10px #55555537;
}
/* Handle */
.Dashboard::-webkit-scrollbar-thumb {
  background: linear-gradient(var(--theme), #000);
  border-radius: var(--borderRadius);
}
/* Handle on hover */
.Dashboard::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.dashboardSelectBackImg div.col-6 {
  padding: 0%;
}
.dashboardSelectBackImg img {
  height: 150px;
  width: 150px;
  border-radius: var(--borderRadius);
}

.Dashboard-row {
  /* height: 100% !important;x */
  min-height: 90vh !important;
}

.dashboardsideBar {
  /* border: 2px solid #8f9a6b; */
  box-shadow: 5px 0px 20px #8f9a6b40;
  /* min-height: 590px !important; */
  /* max-height: 90vh !important; */
  overflow-x: hidden;
  overflow-y: auto;
  transition: 0.3s;
}

.dashboardDetailBar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.dashboardSideBarMacNoContainer {
  max-height: 70vh !important;
  min-height: 360px !important;
  overflow-y: auto;
  /* position: relative; */
}
.dashboardSideBarMacNoContainer li .nav-link {
  margin: 0 auto !important;
}
.dashboardSideBarMacNoContainer li:hover {
  cursor: pointer;
}

/* width */
.dashboardSideBarMacNoContainer::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.dashboardSideBarMacNoContainer::-webkit-scrollbar-track {
  background: transparent;
  background: transparent;
  border-radius: var(--borderRadius);
  box-shadow: -5px 5px 10px #555 inset;
}

/* Handle */
.dashboardSideBarMacNoContainer::-webkit-scrollbar-thumb {
  background: #ffffff8c;

  border-radius: var(--borderRadius);
}

/* Handle on hover */
.dashboardSideBarMacNoContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.companylogo {
  justify-content: center;
  align-items: center !important;
  opacity: 0.9;
  font-weight: 600;
}

.dashboardsideBarItem {
  font-size: x-large;
  font-weight: 600;
  justify-content: center;
  text-align: center;
  /* cursor: pointer; */
  transition: 0.5s;
}

.dashboardsideBarItem:hover {
  color: #8f9a6b;
  text-decoration: underline;
  cursor: pointer;
}

.machineSttisticsCon {
  list-style-type: none;
  margin-block-start: 0% !important;
}

.dashboardOrganizationImgtop {
  max-width: 35px !important;
  min-height: 35px !important;
  max-height: 35px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 2px solid black;
}

.selectedlogotext {
  animation: toleft 0.5s linear 1;
}

.dashboardOrganizationImg {
  /* width: 55px;
  height: 55px; */
  max-width: 200px !important;
  max-height: 200px !important;
  min-height: 200px !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: 0px 0px 10px #00000030;
  /* border: 2px solid black; */
}

.dashboardOrganizationP {
  max-width: 55%;
}

.dashboardMacHeadingUnderline {
  position: relative;
  padding: 0%;
}

.dashboardMacHeadingUnderline::before {
  content: "";
  width: 100%;
  height: fit-content;
  position: absolute;
  bottom: 5%;
  left: 1%;
  z-index: 50;
  height: 3px;
  background: #5a6141;
  animation: increaseline 0.5s 0.5s 2;
}

.mcimg {
  max-height: 80vh;
}

.selbtn {
  font-size: larger;
  font-weight: 700;
  cursor: pointer;
}

/* setting */
.setcolorContainer span {
  height: 20px;
  width: 20px;
  display: block !important;
  margin: 0%;
}

.adminpopupcon {
  width: 100%;
  height: 100%;
  min-height: 300px;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.443);
  display: flex;
  justify-content: center;
  align-items: center;
}

.adminpopup {
  background-color: #fff;
  box-shadow: 0 0 10px #0000009c;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-width: 40%;
  border-radius: 10px;
  position: relative;
  animation: popup 1s forwards 1;
}
.adminpopup::-webkit-scrollbar {
  width: 10px;
  /* height: 5px; */
  overflow: hidden;
  border-radius: 10px;
}
/* Track */
.adminpopup::-webkit-scrollbar-track {
  background: linear-gradient(#676765, #d2d2d2);
}

/* Handle */
.adminpopup::-webkit-scrollbar-thumb {
  background: linear-gradient(#5a6141, #8f9a6b);
  border-radius: 10%;
  height: 50px !important;
}

/* Handle on hover */
.adminpopup::-webkit-scrollbar-thumb:hover {
  background: #8f9a6b;
}

.adminpopup h3 {
  text-align: center;
}

.adminpopup ul {
  list-style-type: none;
  box-sizing: border-box;
}
.adminpopup ul li {
  padding: 0% !important;
  width: 100% !important;
}

.popclsbtn {
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 2%;
  right: 2%;
  cursor: pointer;
  z-index: 20;
}

.macDashboard {
  min-height: 400px !important;
  height: 80vh !important;
  max-height: 590px;
  box-sizing: border-box;
  overflow: hidden;
}

.macDashboard img {
  animation: ScaleupNdown 2s linear 1;
  height: 100%;
  opacity: 0.8;
}

/* ======================================== Machines Dashboard ==================================== */

/* ======================================== Page 404 ==================================== */
.notFound {
  min-height: 300px !important;
  max-height: 400px !important;
  animation: upNdown 2s linear 1;
}
.pgNotfnd h3 {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  opacity: 0.5;
  font-style: italic;
  font-weight: 500;
}
.pgNotfnd button {
  border: 1px solid rgba(0, 0, 0, 0.614);
  background: #8f9a6b;
  font-weight: 500;
  text-transform: capitalize;
  color: #dde1d3;
}
/* ======================================== Page 404 ==================================== */

.bg-none {
  background: transparent;
}

/* ======================================== Animation Section ==================================== */
@keyframes upNdown {
  0%,
  100% {
    scale: 100%;
    transform: translateY(0px);
  }
  50% {
    scale: 103%;
    transform: translateY(-10px);
  }
}
@keyframes ScaleupNdown {
  0%,
  100% {
    scale: 100%;
  }
  50% {
    scale: 103%;
  }
}
@keyframes slideFromLeft {
  0% {
    left: -50%;
  }
  100% {
    left: 0%;
  }
}
@keyframes slideDown {
  0% {
    top: -500%;
    opacity: 0;
  }

  100% {
    top: 0%;
    opacity: 1;
  }
}
@keyframes toleft {
  0% {
    transform: translateX(100%);
    opacity: 0.5;
  }
  60% {
    transform: translateX(0%);
    opacity: 1;
  }
  80% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes increaseline {
  0% {
    width: 0%;
  }
  100% {
    width: 50%;
  }
}
/* ======================================== Animation Section ==================================== */

/* ================================ Responsive Section ============================================ */
@media only screen and (max-width: 992px) {
  form {
    width: 85% !important;
  }
  .profile-container {
    width: 75% !important;
  }
}
@media only screen and (max-width: 767px) {
  .slide-img {
    height: 350px;
    width: 100%;
  }

  .carousel-btns {
    height: 70px;
    width: 50px;
    z-index: 10;
    background-color: black;
  }
  form {
    width: 105% !important;
  }
}
@media only screen and (max-width: 576px) {
  .login-btn {
    padding: 2px 10px;
    font-size: normal;
  }
  .slide-img {
    height: 300px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .carousel-btns {
    height: 70px;
    width: 50px;
    z-index: 10;
    background-color: black;
  }
  form {
    width: 97% !important;
  }
  .profile-container {
    width: 95% !important;
  }

  .table {
    width: 85% !important;
    box-sizing: border-box;
  }

  .table-hover {
    width: 100% !important;
  }

  .container-text .ck-content .table table td,
  .container-text .ck-content .table table th,
  .container-text .ck-content .table td,
  .container-text .ck-content .table th,
  .table table td,
  .table table th,
  .table td,
  .table th {
    width: auto !important;
  }
}
@media all and (min-width: 0px) and (max-width: 576px) {
  .adminpopup {
    max-width: 95% !important;
    width: 100%;
  }
  .btnAtOpen {
    left: 33% !important;
  }
  .dashboardOrganizationP {
    max-width: 95%;
  }
  .dashboardMacHeadingUnderline::before {
    left: 3%;
    width: 100%;
  }
  @keyframes increaseline {
    0% {
      width: 0%;
    }
    100% {
      width: 130%;
    }
  }
  .ChartistGraphWrapper .customLabels span {
    font-size: smaller;
  }
}
@media all and (min-width: 0px) and (max-width: 768px) {
  .navdown {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    padding-top: 58px;
    height: fit-content;
    min-height: fit-content;
    background-color: #fff;
    z-index: 10;
    transition: 1s;
    animation: slideDown 0.3s linear both;
    box-shadow: 0px 10px 20px #8f9a6b53;
    border-bottom-left-radius: 2%;
    border-bottom-right-radius: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .navdown .nav-link {
    align-self: center;
    margin: 3%;
    text-align: center;
  }
  .navdown .login-btn {
    width: fit-content;
    padding: 2% 10%;
    left: -3%;
  }
  /* .navdown .login-btn .nav-link {
    width: 100%;
  } */
  .selectmacBtn {
    position: fixed !important;
    top: 50%;
    left: 0%;
    background-color: #8f9a6b !important;
    color: #fff !important;
    border-radius: 0px !important;
    cursor: pointer;
    z-index: 10;
    box-sizing: border-box;
    transition: 0.3s !important;
  }
  .btnAtOpen {
    left: 24%;
  }
  .dashboardsideBar {
    position: fixed !important;
    flex-direction: column;
    /* top: 13% !important; */
    /* left: 0% !important;
    top: 13%; */
    z-index: 9;
    animation: slideFromLeft 0.2s linear 1;
    height: 100%;
    /* max-height: 200px !important; */
    overflow-y: auto;
    transition: 0.5s;
  }
  .Dashboard .Dashboard-row .dashboardDetailBar .row {
    margin-bottom: 20px;
    height: fit-content !important;
  }

  .Dashboard .Dashboard-row .dashboardDetailBar .row .card {
    margin-bottom: 0% !important;
    height: fit-content;
  }
}
@media all and (min-width: 577px) and (max-width: 768px) {
  /* .navdown {
    height: 85vh !important;
  } */
  .adminpopup {
    max-width: 80% !important ;
    width: 100% !important;
  }
  .adminpopup ul li span {
    display: block;
  }
  .dashboardOrganizationP {
    max-width: 75%;
  }
  .dashboardMacHeadingUnderline::before {
    right: 3%;
  }
  @keyframes increaseline {
    0% {
      width: 0%;
    }
    100% {
      width: 15%;
    }
  }
}
@media all and (min-width: 769px) and (max-width: 992px) {
  .adminpopup {
    max-width: 70% !important;
    width: 100% !important;
  }
  .dashboardMacHeadingUnderline::before {
    left: 3%;
  }
  @keyframes increaseline {
    0% {
      width: 0%;
    }
    100% {
      width: 13%;
    }
  }
}
@media all and (min-width: 993px) and (max-width: 1200px) {
  .adminpopup {
    max-width: 60%;
    width: 100% !important;
  }
}
@media (max-width: 1400px) {
  .adminpopup {
    max-width: 50%;
    width: 100% !important;
  }
}

.ChartistGraphWrapper {
  background-color: #000;
  position: relative;
}

.ChartistGraphWrapper .customLabels {
  display: flex;
  justify-content: space-around;
  width: 100%;
  /* background-color: red; */
  position: absolute;
  bottom: -15%;
}

.ChartistGraphWrapper .customLabels span {
  opacity: 0.5;
}

.Dashboard .Dashboard-row .dashboardDetailBar .row {
  margin-bottom: 20px;
  /* height: fit-content; */
}

.Dashboard .Dashboard-row .dashboardDetailBar .row .card {
  margin-bottom: 0% !important;
  height: 100%;
}

.sidebar-filter-btn {
  background: transparent;
  color: #ffffff50;
  border: 1px solid #ffffff50;
}

.sidebarActive {
  color: #ffffff70;
  background: #ffffff20;
  border: 1px solid #ffffff70;
}
