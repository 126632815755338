.recipeCards {
  cursor: pointer;
  box-shadow: 2px 2px 5px #00000022;
  margin: 0%;
  transition: 0.5s;
}

.showMbtn {
  color: var(--theme);
}

.recipeCards:hover {
  box-shadow: 0px 0px 15px #00000022;
}

.closeBtnOfDetail {
  width: fit-content;
  margin: 0.5rem 1rem;
  background: none;
  border: none;
  position: absolute;
}

.closeBtnOfDetail i {
  color: var(--theme);
}
